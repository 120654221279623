function successResponse(message) {
    return Swal.fire(
        'Success',
        message,
        'success'
      )
}

function errorResponse(message, formID) {
    return Swal.fire(
        'Error',
        message,
        'error'
      )
}

function hideModal(formID) {
    var myModalEl = document.getElementById(formID);
    var modal = bootstrap.Modal.getInstance(myModalEl)
    modal.hide();
}

function showHideDiv() {
    $('#need-help').hide();
    $('#second-opinion-section').hide();
    $('.on-success').show();
}

function redirectThankYouPage() {
    window.location.href = `/thank-you`;
}

jQuery.validator.addMethod("emailfull", function(value, element) {
    return this.optional(element) || /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i.test(value);
    }, "Please enter valid email address!");

$(document).ready(function () {
    $('#file_attachment').change(function() {
        var i = $(this).prev('label').clone();
        var file = $('#file_attachment')[0].files[0].name;
        $(this).prev('label').text(file);
    });

    $('#secondOpinionFileAttachment').change(function() {
        var i = $(this).prev('label').clone();
        var file = $('#secondOpinionFileAttachment')[0].files[0].name;
        $(this).prev('label').text(file);
    });

    $('#internationalOpinionForm').validate({
        rules: {
            name: {
                required: true,
                minlength: 3,
                maxlength: 150,
            },
            phone: {
                required: true,
                minlength: 10,
                maxlength: 12,
                number:true
            },
            country: {
                required: true
            },
            email: {
                required: false,
                emailfull: true
            },
            remark: {
                maxlength: 1000
            },
            tc: {
                required: true
            }
        },
        highlight: function (element) {
            $(element).closest('.inputBox').addClass('is-invalid');
        },
        unhighlight: function (element, errorClass) {
            $(element).closest('.inputBox').removeClass("is-invalid");
        },
        errorElement: "span",
        submitHandler: function (form) {
            var requestedData = $('#internationalOpinionForm').serializeArray();
            let needHelpFormURL = window.location.origin + '/api/need-help';
            var formData = new FormData();
            var filesCollection = $(form).find('input[type=file]')[0].files[0];
            if (typeof filesCollection !== 'undefined') {
              formData.append('attachment', filesCollection);
            }
            $.each(requestedData, function (i, val) {
              formData.append(val.name, val.value);
            });

            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                url: needHelpFormURL,
                type: 'POST',
                data: formData,
                dataType: 'json',
                mimeType: "multipart/form-data",
                processData: false,
                contentType: false,
                beforeSend() {
                    $('.submitButton').prop('disabled', true);
                },
                success: function (response) {
                    if (response.success === true) {
                        var myModalEl = document.getElementById('internationHelplineModal');
                        var modal = bootstrap.Modal.getInstance(myModalEl)
                        modal.hide();
                        $("#internationalOpinionForm")[0].reset();
                        $('.submitButton').prop('disabled', false);
                        successResponse(response.message);
                    }
                },
                error: function (request, status, error) {
                    $('.submitButton').prop('disabled', false);
                    let resp = request.responseJSON;
                    if (resp.status === 422) {
                        let errors = resp.errors;
                        $.each(errors, function (key, val) {
                            $('#' + key).addClass('is-invalid');
                        });
                    }else {
                        errorResponse(error);
                    }
                }
            });
        }
    });

    // Main Header Second Opinion Form
    $("#HeaderSecondOpinionForm").validate({
        rules: {
            name: {
                required: true,
                minlength: 3,
                maxlength: 30,
            },
            phone: {
                required: true,
                minlength: 10,
                maxlength: 12,
                number:true
            },
            file_attachment: {
                extension: "jpg|png|jpeg|pdf|zip|doc|docx"
            },
            speciality: {
                required: true
            },
            tc: {
                required: true
            }
        },
        highlight: function (element) {
            $(element).closest('.inputBox').addClass('is-invalid');
        },
        unhighlight: function (element, errorClass) {
            $(element).closest('.inputBox').removeClass("is-invalid");
        },
        errorElement: "span",
        submitHandler: function (form) {
            let formInputData = $('#HeaderSecondOpinionForm').serializeArray();
            let secondOpinionFormURL = window.location.origin + '/api/second-opinion';
            let formData = new FormData();
            let filesCollection = $('input[type=file]')[0].files[0];

            if(typeof filesCollection !== 'undefined') {
                formData.append('file_attachment', filesCollection);
            }

            $.each(formInputData, function(i, val) {
                formData.append(val.name, val.value);
            });

            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                url: secondOpinionFormURL,
                type: 'POST',
                data: formData,
                dataType: 'json',
                mimeType: "multipart/form-data",
                processData: false,
                contentType: false,
                beforeSend() {
                    $('.secondOpinionBtn').prop('disabled', true);
                },
                success: function (response) {
                    if (response.success === true) {
                        $("#HeaderSecondOpinionForm")[0].reset();
                        $('.file-dropzone label').text('Choose File');
                        $('.secondOpinionBtn').prop('disabled', false);
                        successResponse(response.message);
                    }
                },
                error: function (request, status, error) {
                    $('.secondOpinionBtn').prop('disabled', false);
                    let resp = $.parseJSON(request.responseText);
                    if(request.responseJSON.message == 'Too Many Attempts.') {
                        errorResponse(request.responseJSON.message)
                    }
                    if (resp.status === 422 || resp.hasOwnProperty('errors')) {
                        let errors = resp.errors;
                        $.each(errors, function (key, val) {
                            if (key == 'file_attachment') {
                                errorResponse(val[0])
                            }
                            $('#' + key).addClass('is-invalid');
                        });
                    }
                }
            });
        }
    });

    // Call Back Form
    $("#callBackForm").validate({
        rules: {
            name: {
                required: true,
                minlength: 3,
                maxlength: 30,
            },
            phone: {
                required: true,
                minlength: 10,
                maxlength: 12,
                number:true
            },
            email: {
                required: false,
                emailfull: true
            },
            remark: {
              maxlength: 1000
            },
            tc: {
                required: true
            }
        },
        highlight: function (element) {
            $(element).closest('.inputBox').addClass('is-invalid');
        },
        unhighlight: function (element, errorClass) {
            $(element).closest('.inputBox').removeClass("is-invalid");
        },
        errorElement: "span",
        submitHandler: function (form) {
            console.log(form);
            let requestedData = $('#callBackForm').serialize();
            let needHelpFormURL = window.location.origin + '/api/need-help';
            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                url: needHelpFormURL,
                type: 'POST',
                data: requestedData,
                dataType: 'json',
                beforeSend() {
                    $('.needHelpSubmitBtn').prop('disabled', true);
                },
                success: function (response) {
                    if (response.success === true) {
                        $("#callBackForm")[0].reset();
                        $('.needHelpSubmitBtn').prop('disabled', false);
                        showHideDiv();
                    }
                },
                error: function (request, status, error) {
                    $('.needHelpSubmitBtn').prop('disabled', false);
                    let resp = request.responseJSON;
                    if (resp.status === 422) {
                        let errors = resp.errors;
                        $.each(errors, function (key, val) {
                            $('#' + key).addClass('is-invalid');
                        });
                    }else {
                        errorResponse(error);
                    }
                }
            });
        }
    });

    // Health Package Form
    $("#healthPackageForm").validate({
        focusCleanup: true,
        rules: {
            patient_name: {
                required: true,
                minlength: 3,
                maxlength: 150,
            },
            patient_phone: {
                required: true,
                minlength: 10,
                maxlength: 12,
                number:true
            },
            patient_email: {
                required: true,
                emailfull: true
            },
            patient_gender: {
                required: true,
            },
            appointment_date: {
                required: true,
            },
            location: {
                required: true
            },
            remark: {
                maxlength: 1000
            }
        },
        highlight: function (element) {
            $(element).closest('.inputBox').addClass('is-invalid');
        },
        unhighlight: function (element, errorClass) {
            $(element).closest('.inputBox').removeClass("is-invalid");
        },
        errorElement: "span",
        submitHandler: function (form) {
            let requestedData = $('#healthPackageForm').serialize();
            let heathPackageURL = window.location.href;
            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                url: heathPackageURL,
                type: 'POST',
                data: requestedData,
                dataType: 'json',
                beforeSend() {
                    $('.php-btn').prop('disabled', true);
                },
                success: function (response) {
                    if (response.success === true) {
                        $("#healthPackageForm")[0].reset();
                        $('.php-btn').prop('disabled', false);
                        redirectThankYouPage();
                    }
                },
                error: function (request, status, error) {
                    errorResponse(error);
                    $('.php-btn').prop('disabled', false);
                    let resp = $.parseJSON(request.responseText);
                    if (resp.status === 422) {
                        let errors = resp.errors;
                        $.each(errors, function (key, val) {
                            $('#' + key).addClass('is-invalid');
                        });
                    }
                }
            });
        }
    });

    $("#appointmentCallbackForm").validate({
        rules: {
            name: {
                required: true,
                minlength: 3,
                maxlength: 30,
            },
            phone: {
                required: true,
                minlength: 10,
                maxlength: 12,
                number:true
            },
            email: {
                required: false,
                emailfull: true
            },
            tc: {
                required: true
            }
        },
        highlight: function (element) {
            $(element).closest('.inputBox').addClass('is-invalid');
        },
        unhighlight: function (element, errorClass) {
            $(element).closest('.inputBox').removeClass("is-invalid");
        },
        errorElement: "span",
        submitHandler: function (form) {
            console.log(form);
            let requestedData = $('#appointmentCallbackForm').serialize();
            let needHelpFormURL = window.location.origin + '/api/need-help';
            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                url: needHelpFormURL,
                type: 'POST',
                data: requestedData,
                dataType: 'json',
                beforeSend() {
                    $('#appointmentCallbackReq').prop('disabled', true);
                },
                success: function (response) {
                    hideModal('doctorAppointmentModal');
                    if (response.success === true) {
                        $("#appointmentCallbackForm")[0].reset();
                        $('#appointmentCallbackReq').prop('disabled', false);
                        successResponse(response.message);
                    }
                },
                error: function (request, status, error) {
                    hideModal('doctorAppointmentModal')
                    $('#appointmentCallbackReq').prop('disabled', false);
                    let resp = request.responseJSON;
                    if (resp.status === 422) {
                        let errors = resp.errors;
                        $.each(errors, function (key, val) {
                            $('#' + key).addClass('is-invalid');
                        });
                    }else {
                        errorResponse(error);
                    }
                }
            });
        }
    });

    $(".closebtn").click(function() {
        $("#appointmentCallbackForm").validate().resetForm();
    });

    // Contact Form
    $("#contactForm").validate({
        rules:{
            name:{
                required: true,
                minlength: 3,
                maxlength: 150,
            },
            phone:{
                required: true,
                minlength: 5,
                maxlength: 10,
                number:true
            },
            hospital: {
                required: true
            },
            remark: {
                maxlength: 1000
            }
        },
        messages: {
            phone:{
                number:"Only number allowed"
            }
        },
        highlight: function (element) {
            $(element).closest('.inputBox').addClass('is-invalid');
        },
        unhighlight: function (element, errorClass) {
            $(element).closest('.inputBox').removeClass("is-invalid");
        },
        errorElement: "span",
        submitHandler: function(form) {
            let requestedData = $('#contactForm').serialize();
            let needHelpFormURL = window.location.origin+'/api/need-help';
            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                url: needHelpFormURL,
                type: 'POST',
                data: requestedData,
                dataType: 'json',
                beforeSend() {
                    $('.submitButton').prop('disabled', true);
                },

                success: function(response) {
                    if (response.success === true) {
                        $("#contactForm")[0].reset();
                        $('.submitButton').prop('disabled', false);
                        redirectThankYouPage();
                    }
                },

                error: function (request, status, error) {
                    errorResponse(error);
                    $('.submitButton').prop('disabled', false);
                    let resp = $.parseJSON(request.responseText);
                    if(resp.status === 422 ) {
                        let errors = resp.errors;
                        $.each(errors, function (key, val) {
                            $('#'+key).addClass('is-invalid');
                        });
                    }
                }
            });
        }
    });

    // Second Opinion Form
    $("#secondOpinionForm").validate({
        rules: {
            name: {
                required: true,
                minlength: 3,
                maxlength: 30,
            },
            phone: {
                required: true,
                minlength: 10,
                maxlength: 12,
                number:true
            },
            file_attachment: {
                extension: "jpg|png|jpeg|pdf|zip|doc|docx"
            },
            // speciality: {
            //     required: true
            // },
            tc: {
                required: true
            }
        },
        highlight: function (element) {
            $(element).closest('.inputBox').addClass('is-invalid');
        },
        unhighlight: function (element, errorClass) {
            $(element).closest('.inputBox').removeClass("is-invalid");
        },
        errorElement: "span",
        submitHandler: function (form) {

            let formInputData = $('#secondOpinionForm').serializeArray();
            let secondOpinionFormURL = window.location.origin + '/api/second-opinion';
            let formData = new FormData();
            let filesCollection = $(form).find('input[type=file]')[0].files[0];

            if(typeof filesCollection !== 'undefined') {
                formData.append('file_attachment', filesCollection);
            }

            $.each(formInputData, function(i, val) {
                formData.append(val.name, val.value);
            });

            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                url: secondOpinionFormURL,
                type: 'POST',
                data: formData,
                dataType: 'json',
                mimeType: "multipart/form-data",
                processData: false,
                contentType: false,
                beforeSend() {
                    $('.secondOpinionBtn').prop('disabled', true);
                },

                success: function (response) {
                    if (response.success === true) {
                        $("#secondOpinionForm")[0].reset();
                        $('.secondOpinionBtn').prop('disabled', false);
                        showHideDiv();
                    }
                },
                error: function (request, status, error) {
                    errorResponse(error);
                    $('.secondOpinionBtn').prop('disabled', false);
                    let resp = $.parseJSON(request.responseText);
                    if (resp.status === 422) {
                        let errors = resp.errors;
                        $.each(errors, function (key, val) {
                            $('#' + key).addClass('is-invalid');
                        });
                    }
                }
            });
        }
    });

     // Second Opinion Form
     $("#writeToChairmanForm").validate({
        rules: {
            name: {
                required: true,
                minlength: 3,
                maxlength: 30,
            },
            phone: {
                required: true,
                minlength: 10,
                maxlength: 12,
                number:true
            }
        },
        highlight: function (element) {
            $(element).closest('.inputBox').addClass('is-invalid');
        },
        unhighlight: function (element, errorClass) {
            $(element).closest('.inputBox').removeClass("is-invalid");
        },
        errorElement: "span",
        submitHandler: function (form) {

            let formInputData = $('#writeToChairmanForm').serializeArray();
            let writeToChairmanFormURL = window.location.origin + '/api/write-to-chairman/store';
            let formData = new FormData();

            $.each(formInputData, function(i, val) {
                formData.append(val.name, val.value);
            });

            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                url: writeToChairmanFormURL,
                type: 'POST',
                data: formData,
                dataType: 'json',
                processData: false,
                contentType: false,
                beforeSend() {
                    $('#chairmanMsgSubmit').prop('disabled', true);
                },

                success: function (response) {
                    var myModalEl = document.getElementById('writeToChairman');
                    var modal = bootstrap.Modal.getInstance(myModalEl)
                    modal.hide();

                    if (response.success === true) {
                        $("#writeToChairmanForm")[0].reset();
                        $('#chairmanMsgSubmit').prop('disabled', false);
                        successResponse(response.message);
                    }
                },
                error: function (request, status, error) {
                    $('#chairmanMsgSubmit').prop('disabled', false);
                    let resp = $.parseJSON(request.responseText);
                    if (resp.status === 422) {
                        let errors = resp.errors;
                        $.each(errors, function (key, val) {
                            $('#' + key).addClass('is-invalid');
                        });
                    }else {
                        var myModalEl = document.getElementById('writeToChairman');
                        var modal = bootstrap.Modal.getInstance(myModalEl)
                        modal.hide();
                        errorResponse(error);
                    }
                }
            });
        }
    });

    // Career Subscribe Form
    $('.careerSubsBtn').on('click', function (e) {
        e.preventDefault();
        let inputValue = $('#careerSubscribe').val();
        let subscribeFormURL = window.location.origin + '/api/subscribe-newsletter';
        $('.careerEmailError').text('');

        $.ajax({
            type: "POST",
            url: subscribeFormURL,
            data: { email: inputValue },
            beforeSend() {
                $('.careerSubsBtn').attr('disabled', true)
            },
            success: function (response) {
                $('.careerEmailError').text('');
                $('.careerSubsBtn').attr('disabled', false)
                $('#careerSubscribe').val('');
                successResponse(response.message);
            },
            error: function (request, error) {
                let resp = request.responseJSON;
                $('.careerSubsBtn').attr('disabled', false)
                if (resp.status === 422) {
                    let errors = resp.errors;
                    $.each(errors, function (key, val) {
                        $('.careerEmailError').text(val[0]);
                    });
                } else {
                    errorResponse(error);
                }
            }
        })
    })

    // Newsletter Subscriber Form
    $('.subscribeMeBtn').on('click', function (e) {
        e.preventDefault();
        let inputValue = $('#subscribeEmail').val();
        let subscribeFormURL = window.location.origin + '/api/subscribe-newsletter';
        $('.emailErrorValidation').text('');

        $.ajax({
            type: "POST",
            url: subscribeFormURL,
            data: { email: inputValue },
            beforeSend() {
                $('.subscribeMeBtn').attr('disabled', true)
            },
            success: function (response) {
                $('.emailErrorValidation').text('');
                $('.subscribeMeBtn').attr('disabled', false)
                $('#subscribeEmail').val('');
                successResponse(response.message);
            },
            error: function (request, error) {
                let resp = request.responseJSON;
                $('.subscribeMeBtn').attr('disabled', false)
                if (resp.status === 422) {
                    let errors = resp.errors;
                    $.each(errors, function (key, val) {
                        $('.emailErrorValidation').text(val[0]);
                    });
                } else {
                    errorResponse(error);
                }
            }
        })
    })

    // Apply Job
    $("#jobApplyForm").validate({
        rules: {
            name: {
                required: true,
                minlength: 3,
                maxlength: 30,
            },
            phone: {
                required: true,
                minlength: 10,
                maxlength: 12,
                number: true
            },
            email: {
                required: false,
                emailfull: true
            },
            qualification: {
                required: true,
            },
            location: {
                required: true,
            },
            CurrentCTC: {
                required: true,
                digits: true
            },
            ExpectedCTC: {
                required: true,
                digits: true
            },
            Experience: {
                required: true,
            }
        },
        highlight: function(element) {
            $(element).closest('.inputBox').addClass('is-invalid');
        },
        unhighlight: function(element, errorClass) {
            $(element).closest('.inputBox').removeClass("is-invalid");
        },
        errorElement: "span",
        submitHandler: function(form) {
            let formInputData = $('#jobApplyForm').serializeArray();
            let needHelpFormURL = window.location.origin + '/api/apply-job';
            let formData = new FormData();
            let filesCollection = $(form).find('input[type=file]')[0].files[0];

            if(typeof filesCollection !== 'undefined') {
                formData.append('resume', filesCollection);
            }

            $.each(formInputData, function(i, val) {
                formData.append(val.name, val.value);
            });

            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                url: needHelpFormURL,
                type: 'POST',
                data: formData,
                dataType: 'json',
                mimeType: "multipart/form-data",
                processData: false,
                contentType: false,
                beforeSend() {
                    $('#modal-job-apply').prop('disabled', true);
                },
                success: function(response) {
                    var myModalEl = document.getElementById('JobApplyModal');
                    var modal = bootstrap.Modal.getInstance(myModalEl)
                    modal.hide();
                    if (response.success === true) {
                        $("#jobApplyForm")[0].reset();
                        $('#modal-job-apply').prop('disabled', false);
                        setTimeout(function() {
                            Swal.fire(
                                'Applied',
                                response.message,
                                'success'
                            )
                        }, 1500);
                    }
                },
                error: function(request, status, error) {
                    $('#modal-job-apply').prop('disabled', false);
                    let resp = request.responseJSON;
                    if (resp.status === 422) {
                        let errors = resp.errors;
                        $.each(errors, function(key, val) {
                            $('#' + key).addClass('is-invalid');
                        });
                    } else {
                        errorResponse(error);
                    }
                }
            });
        }
    });
})

$(document).ready(function () {
    let qInput = $('.menu-search');

    qInput.on('keyup', function () {
        if ($(this).val().length >= 3) {
            let searchQuery = $(this).val();
            let apiURL = window.location.origin + '/api/menu-search';
            hideMenuSearch();
            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                url: apiURL,
                dataType: "json",
                type: "POST",
                async: true,
                data: {
                    searchQuery: searchQuery
                },
                success: function (response) {
                    if ((response.data).length == 0) {
                        hideMenuSearch();
                    } else {
                        hideMenuSearch();
                        $.each(response.data, function (key, value) {
                            if (value.type == 'doctor') {
                                $('.menu-doctor').css('display', 'block');
                                if (value.img == '') {
                                    src = (value.gender == 'Male') ?
                                        'img/default-doctor-male.jpg' :
                                        'img/default-doctor-female.jpg';
                                    src = window.location.protocol + "//" +
                                        window.location.host + '/' + src;
                                } else {
                                    src = value.img;
                                }
                                href = window.location.protocol + "//" + window
                                    .location.host + '/doctor/' + value.id;
                                let responseHTML =
                                    `<li><a href="${href}" class="no-underline"><img src="${src}" alt=""/><span>${value.match}</span></a></li>`;
                                $('.menu-doctor ul').append(responseHTML);
                            }
                            if (value.type == 'speciality') {
                                $('.menu-speciality').css('display', 'block');
                                if (value.img == '') {
                                    src = 'img/default-doctor-female.jpg';
                                    src = window.location.protocol + "//" +
                                        window.location.host + '/' + src;
                                } else {
                                    src = value.img;
                                }
                                href = window.location.protocol + "//" + window
                                    .location.host + '/specialities/' + value.id;
                                let responseHTML =
                                    `<li><a href="${href}" class="no-underline"><img src="${src}" alt=""/><span>${value.match}</span></a></li>`;
                                $('.menu-speciality ul').append(responseHTML);
                            }
                            if (value.type == 'treatment') {
                                $('.menu-treatment').css('display', 'block');
                                href = window.location.protocol + "//" + window
                                    .location.host + '/treatment/' + value.id;
                                let responseHTML =
                                    `<li><a href="${href}" class="no-underline"><span>${value.match}</span></a></li>`;
                                $('.menu-treatment ul').append(responseHTML);
                            }
                            if (value.type == 'hospital') {
                                $('.menu-hospital').css('display', 'block');
                                if (value.img == '') {
                                    src = 'img/hospital1.png';
                                    src = window.location.protocol + "//" +
                                        window.location.host + '/' + src;
                                } else {
                                    src = value.img;
                                }
                                href = window.location.protocol + "//" + window
                                    .location.host + '/hospitals/' + value.id;
                                let responseHTML =
                                    `<li><a href="${href}" class="no-underline"><img src="${src}" alt=""/><span>${value.match}</span></a></li>`;
                                $('.menu-hospital ul').append(responseHTML);
                            }
                            if (value.type == 'blog') {
                                $('.menu-blog').css('display', 'block');
                                //if (value.img == '') {
                                //    src = 'img/circle-arrow-o.png';
                                //    src = window.location.protocol + "//" +
                                //        window.location.host + '/' + src;
                                //} else {
                                //    src = value.img;
                                //}
                                href = window.location.protocol + "//" + window
                                    .location.host + '/blog/' + value.id;
                                let bvalue=`${value.match}`;
                                //bvalue.replace(/'/g, '&quot;');
                                //bvalue.replace(/"/g, '&quot;');
                                if (bvalue.length < 55){
                                    trimmedString=bvalue;
                                }else{
                                var trimmedString = bvalue.substr(0, 55);
                                trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
                                trimmedString =trimmedString + '...';
                                }
                                //if (trimmedString==bvalue)?trimmedString: trimmedString +'...';
                                let responseHTML =
                                    `<li><a href="${href}" class="no-underline"><span>${trimmedString}</span></a></li>`;
                                $('.menu-blog ul').append(responseHTML);
                            }
                        });
                        $('.suggestion-filter-doctors').css('display', 'block');
                    }
                },
                error: function (error) {
                    hideMenuSearch();
                }
            });
        } else {
            hideMenuSearch();
        }
    })
});

function hideMenuSearch() {
    $('.menu-doctor').css('display', 'none');
    $('.menu-speciality').css('display', 'none');
    $('.menu-treatment').css('display', 'none');
    $('.menu-hospital').css('display', 'none');
    $('.menu-blog').css('display', 'none');
    $('.menu-doctor li').remove();
    $('.menu-speciality li').remove();
    $('.menu-treatment li').remove();
    $('.menu-hospital li').remove();
    $('.menu-blog li').remove();
    $('.suggestion-filter-doctors').css('display', 'none');
}

$(document).ready(function() {
    $(document).on('click', 'body', function(e) {
      if (($(e.target).is('.h-22')) || ($(e.target).is('.menu-search') )){
        //pass
      }
      else{
        $('.search-overlay-wrapper').removeClass('show-search');
        hideMenuSearch();
      }
    })
});

